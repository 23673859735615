body {
    margin: 0;
    font-family: "Inter", sans-serif;
}

a {
    text-decoration: none;
}

:root {
    /* --primary: #32B1D9; */
    --primary: #650c9c;
    --secondary: #568100;
    --darkClr: #040136;
    --neutral-50: #F5F6FA;
    /* --primary-600: #32b1d9; */
    --primary-600: #e77324;
    --neutral-300: #D1D5DB;
    --input-bg: var(--neutral-50);
    --brand: var(--primary-600);
    --input-form-light: var(--neutral-300);
}

.btn-primary {
    background-color: var(--primary);
    border: solid 1px var(--primary)
}

.btn-primary:hover {
    background-color: var(--darkClr);
    border: solid 1px var(--darkClr)
}

.btn-secondary {
    background-color: var(--secondary);
    border: solid 1px var(--secondary);
    color: var(--darkClr);
}

.main_header .dropdown-menu.show {
    top: 20px !important;
}

.notification_list {
    min-width: 300px;
}

p {
    font-size: 15px;
    line-height: 25px;
    color: #666;
}

.notification_list li {
    border-bottom: 1px #e5e5e5 solid;
}

.notification_list li:last-child {
    border: 0;
}

.notification_list li p {
    margin: 0;
}

.notification_list li a {
    color: #000;
}

.top_header_ico {
    color: #242424;
    text-decoration: none;
}

.nav-link.active {
    color: var(--primary);
}

.main_header {
    box-shadow: 0px 2px 30px #00000014;
    position: relative;
}

.main_header.fixed {
    width: 100%;
    background-color: #fff;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9;
}

.search-ico {
    position: absolute;
    top: 12px;
    left: 11px;
    color: #8d8b8b;
}

.search-header {
    position: relative;
}

.search-header input {
    padding-left: 40px;
}

.banner_section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 80vh;
    background-image: url(../images/banner-bg.jpg);
    overflow: hidden;
}

.banner_section h1 {
    color: #000000;
    margin-bottom: 20px;
}

.banner_section h1 span {
    color: var(--primary);
}

.banner_section p {
    font-size: 17px;
}

.QuickCountSec {
    width: 100%;
    display: inline-block;
    box-shadow: 0px 0px 30px #00000014;
    padding: 10px 0;
}

.QuickCountSec span {
    width: 100%;
    display: inline-block;
    color: #4D4D4D;
    font-weight: 300;
    text-align: center;
}

.QuickCountSec strong {
    width: 100%;
    display: inline-block;
    color: var(--primary);
    font-weight: 600;
    text-align: center;
    font-size: 30px;
}

.QuickCountSec .col-md-3 {
    border-right: 1px #f3f3f3 solid;
}

.QuickCountSec .col-md-3:last-child {
    border: 0;
}


.home_category_section {
    width: 100%;
    display: inline-block;
    padding: 80px 0;
}

.main_heading_txt h2 {
    color: #2e2e2e;
    /* color: #e77324; */
    font-size: 32px;
}

.category_box {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 15px;
    box-shadow: 0px 6px 20px #ededed;
    margin-bottom: 20px;
    transition: 0.2s ease;
}

.category_box:hover {
    transform: translateY(-10px);
}

.category_box_ico {
    color: var(--primary);
    min-width: 50px;
    font-size: 32px;
    text-align: center;
}

.category_box_cnt strong {
    width: 100%;
    display: inline-block;
    color: var(--darkClr);
    font-size: 20px;
}
.category_box_cnt_skeleton {
    flex-grow: 1; /* Allow content to take remaining space */
    display: flex;
    flex-direction: column; /* Stack span and p vertically */
    gap: 5px; /* Space between span and p */
}

.category_box_cnt span {
    font-size: 14px;
    color: #666;
}

.viewAllBtn {
    width: 100%;
    display: inline-block;
    text-align: center;
    margin-top: 20px;
    color: #666;
}

.viewAllBtn a {
    /* color: #666; */
    color: #a89f9f;
    font-size: 12px;
    transition: 0.2s ease-in;
}

.viewAllBtn a:hover {
    color: var(--primary);
}

.courses_section {
    width: 100%;
    display: inline-block;
    padding: 80px 0;
    /* background-color: var(--secondary); */
    background-color: #fafbf7;
}

.category_selection_home {
    color: #666;
    font-size: 15px;
    text-decoration: none;
    display: flex;
    gap: 20px;
    justify-content: end;
}

.category_selection_home a {
    /* color: #666; */
    color: #a89f9f;
    transition: 0.2s ease-in;
}

.category_selection_home a:hover {
    color: var(--primary);
}

.category_selection_home .active {
    color: var(--primary);
}

.course_scroll_row {
    width: 100%;
    display: inline-block;
    margin-top: 30px;
}

/* .course_box {
    width: 100%;
    display: inline-block;
    background-color: #fff;
    padding: 5px;
    box-shadow: 0px 2px 20px #0000001a;
    margin-bottom: 20px;
} */
.course_box {
    width: 100%;
    display: inline-block;
    background-color: #fff;
    padding: 5px;
    box-shadow: 0px 2px 20px #0000001a;
    margin-bottom: 20px;
    height: 400px; /* Set fixed height */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Ensures the footer stays at the bottom */
}

/* .course_box_img {
    width: 100%;
    display: inline-block;
    overflow: hidden;
    
}

.course_box_img img {
    width: 100%;
    transition: 0.2s ease;
} */
.course_box_img {
    width: 100%;
    height: 200px; /* Set a fixed height for the image container */
    display: inline-block;
    overflow: hidden;
}

.course_box_img img {
    width: 100%;
    height: 100%; /* Ensures the image takes up the full height of the container */
    object-fit: cover; /* Keeps the image's aspect ratio while covering the container */
    transition: 0.2s ease;
}

.course_box:hover .course_box_img img {
    transform: scale(1.1); /* Zoom effect on hover */
}


.course_box:hover .course_box_img img {
    transform: scale(1.1);
}

.course_box_cnt {
    width: 100%;
    display: inline-block;
    padding: 10px;
}
.course_box_cnt p{
    word-wrap: anywhere;
}
.course_box_cnt_first_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    color: #666;
}

.courseSlide {
    width: 100%;
    display: inline-block;
    margin-top: 20px;
}

.course_box_cnt_head {
    width: 100%;
    display: inline-block;
    color: #000000;
    font-size: 22px;
}

.course_box_cnt_first_row i {
    color: #ECB800;
}

.course_box_cnt_footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    color: #666;
    border-top: 1px #f1f1f1 solid;
    padding-top: 10px;
}

.course_box_cnt_footer a {
    color: #000;
}

.course_box_cnt_footer .rate {
    color: var(--primary);
    font-size: 17px;
    font-weight: 400;
}






.aboutUs_section {
    width: 100%;
    display: inline-block;
    padding: 80px 0;
}

.testimonials_section {
    width: 100%;
    display: inline-block;
    padding: 80px 0;
}

.testimonial_srl_dv {
    width: 100%;
    display: inline-block;
    margin-top: 20px;
}

.testimonial_box {
    width: 100%;
    display: inline-block;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0px 0px 10px #efefef;
    margin: 15px 0;
}

.testimonial_box_head {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
}

.testimonial_box_profile {
    width: 30px;
    height: 30px;
    min-width: 30px;
    background-color: #f3f3f3;
    border-radius: 30px;
    overflow: hidden;
}

.testimonial_box_profile img {
    width: 100%;
    height: 100%;
}

.testimonial_box_profile_name_dsg {
    color: #000;
    width: 100%;
    display: inline-block;
    line-height: 18px;

}

.testimonial_box_profile_name_dsg span {
    font-size: 13px;
    color: #666;
    width: 100%;
    display: inline-block;
}

.testimonial_box_cnt {
    font-size: 15px;
    line-height: 22px;
    color: #000;
    width: 100%;
    display: inline-block;
    margin-top: 20px;
}

.footer_section {
    width: 100%;
    display: inline-block;
    padding: 40px 0;
    padding-top: 60px;
    background-color: var(--secondary);
}

.footer_box {
    width: 100%;
    display: inline-block;
}

.footer_box_head {
    width: 100%;
    display: inline-block;
    font-size: 18px;
    color: #000;
    margin-bottom: 15px;
}

.footer_box ul {
    padding: 0;
}

.footer_box li {
    list-style: none;
    margin-bottom: 8px;
}

.footer_box p {
    font-size: 14px;
    color: #363636;
}

.footer_box li a {
    font-size: 14px;
    color: #363636;
    line-height: 22px;
    transition: 0.2s ease;
}

.footer_box li a:hover {
    color: var(--primary);
}

.footer_copyright {
    width: 100%;
    display: inline-block;
    border-top: 1px #e5e5e5 solid;
    padding-top: 30px;
}

.footer_copyright_cnt {
    font-size: 13px;
    /* color: #363636; */
    color: #650c9c;
    display: flex;
    justify-content: space-between;
}

.footer_copyright_cnt a {
    font-size: 13px;
    /* color: #363636; */
    color: #650c9c;
}

.course_banner_sec {
    width: 100%;
    display: inline-block;
    background-image: url(../images/course_detail_banner.jpg);
    background-size: cover;
    padding: 30px 0;
}

.course_banner_sec span {
    width: 100%;
    display: inline-block;
    color: #fff;
    font-size: 15px;
}

.course_banner_sec p {
    width: 100%;
    display: inline-block;
    color: #fff;
    font-size: 15px;
    margin: 0;
}

.course_banner_sec h4 {
    width: 100%;
    display: inline-block;
    color: #fff;
    font-size: 33px;
    text-transform: capitalize;
}

.star_rating .fa-star.active {
    color: #ECB800;
}

.star_rating .fa-star {
    color: #9c9c9c;
}

.course_bann_img {
    width: 100%;
    display: inline-block;
}

.course_content_sec {
    width: 100%;
    display: inline-block;
    padding: 40px 0;
}

.course_dtl_level_box {
    width: 100%;
    display: inline-block;
    background-color: #fff;
    box-shadow: 0px 0px 20px #e5e5e5;
}

.course_dtl_level_row {
    width: 100%;
    display: inline-block;
    border-bottom: 1px #e5e5e5 solid;
    padding: 20px;
}

.course_dtl_level_row span {
    width: 100%;
    display: inline-block;
    font-size: 13px;
    color: #363636;
}

.btn_add_card {
    width: 100%;
    display: flex;
    background-color: var(--primary);
    padding: 10px 15px;
    color: #fff;
    font-size: 15px;
    text-align: left;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    transition: 0.2s ease;
}

.btn_add_card:hover {
    background-color: var(--darkClr);
}

.center_line {
    width: 1px;
    height: 40px;
    border-right: 1px rgba(255, 255, 255, 0.267) solid;
}

.btn_add_card strong {
    font-size: 22px;
}

.btn_add_card p {
    margin: 0;
    color: #fff;
    font-size: 12px;
}

.course_content_sec .nav-tabs {
    border: 0;
}

.course_content_sec .nav-tabs .nav-link {
    border: 0;
    color: #999999;
}

.course_content_sec .nav-tabs .nav-link.active {
    border-bottom: 2px solid var(--primary);
    color: var(--primary);
}

.tab-content li {
    font-size: 15px;
    line-height: 25px;
    color: #242424;
}

.course_banner_sec .btn_add_card {
    max-width: 300px;
    margin-top: 10px;
}

.course_banner_sec .btn_add_card span {
    width: auto;
    max-width: 130px;
}

.moduleAccordion .accordion-button p {
    margin: 0;
    font-size: 13px;
    color: #666;
}

.product-reviews {}

.product-reviews .rating {
    margin: 0;
    padding: 0;
    border: none;
}

.rating__group {
    position: relative;
    width: 79px;
    height: 14px;
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93074 1.28395C6.33601 0.349362 7.66139 0.349361 8.06665 1.28395L9.29259 4.11107L12.3602 4.40338C13.3743 4.50001 13.7838 5.76052 13.0202 6.43476L10.7103 8.47433L11.3802 11.4821C11.6017 12.4764 10.5295 13.2555 9.65225 12.7376L6.9987 11.171L4.34515 12.7376C3.46794 13.2555 2.39569 12.4764 2.61716 11.4821L3.2871 8.47433L0.977173 6.43476C0.213564 5.76052 0.623127 4.50001 1.63721 4.40338L4.7048 4.11108L5.93074 1.28395ZM8.22464 4.57418L6.9987 1.74705L5.77276 4.57418C5.60409 4.96315 5.23727 5.22965 4.81522 5.26987L1.74763 5.56218L4.05755 7.60175C4.37536 7.88236 4.51547 8.31357 4.4233 8.7274L3.75336 11.7352L6.40691 10.1686C6.772 9.95304 7.2254 9.95304 7.59049 10.1686L10.244 11.7352L9.5741 8.7274C9.48193 8.31357 9.62204 7.88236 9.93985 7.60175L12.2498 5.56218L9.18217 5.26987C8.76012 5.22965 8.39331 4.96315 8.22464 4.57418Z' fill='%23B3B7BC'/%3E%3C/svg%3E");
    background-size: 16px 14px;
}

.rating__group:focus-within {
    outline: 1px solid #4a90e2;
}

.rating__star {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    height: 14px;
    font-size: inherit;
    appearance: none;
    background-size: 16px 14px;
}

.rating__star:not(:last-child) {
    margin-right: 2px;
}

.rating__star:nth-of-type(1) {
    z-index: 5;
    width: 16px;
}

.rating__star:nth-of-type(2) {
    z-index: 4;
    width: 32px;
}

.rating__star:nth-of-type(3) {
    z-index: 3;
    width: 48px;
}

.rating__star:nth-of-type(4) {
    z-index: 2;
    width: 63px;
}

.rating__star:nth-of-type(5) {
    z-index: 1;
    width: 79px;
}

.rating__star:focus {
    outline: none;
}

/* .rating__star:hover, */
.rating__star:checked {
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93074 1.28395C6.33601 0.349362 7.66139 0.349361 8.06665 1.28395L9.29259 4.11107L12.3602 4.40338C13.3743 4.50001 13.7838 5.76052 13.0202 6.43476L10.7103 8.47433L11.3802 11.4821C11.6017 12.4764 10.5295 13.2555 9.65225 12.7376L6.9987 11.171L4.34515 12.7376C3.46794 13.2555 2.39569 12.4764 2.61716 11.4821L3.2871 8.47433L0.977173 6.43476C0.213564 5.76052 0.623127 4.50001 1.63721 4.40338L4.7048 4.11108L5.93074 1.28395Z' fill='%23F89828'/%3E%3C/svg%3E");
}

.rating__star:hover~.rating__star {
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93074 1.28395C6.33601 0.349362 7.66139 0.349361 8.06665 1.28395L9.29259 4.11107L12.3602 4.40338C13.3743 4.50001 13.7838 5.76052 13.0202 6.43476L10.7103 8.47433L11.3802 11.4821C11.6017 12.4764 10.5295 13.2555 9.65225 12.7376L6.9987 11.171L4.34515 12.7376C3.46794 13.2555 2.39569 12.4764 2.61716 11.4821L3.2871 8.47433L0.977173 6.43476C0.213564 5.76052 0.623127 4.50001 1.63721 4.40338L4.7048 4.11108L5.93074 1.28395ZM8.22464 4.57418L6.9987 1.74705L5.77276 4.57418C5.60409 4.96315 5.23727 5.22965 4.81522 5.26987L1.74763 5.56218L4.05755 7.60175C4.37536 7.88236 4.51547 8.31357 4.4233 8.7274L3.75336 11.7352L6.40691 10.1686C6.772 9.95304 7.2254 9.95304 7.59049 10.1686L10.244 11.7352L9.5741 8.7274C9.48193 8.31357 9.62204 7.88236 9.93985 7.60175L12.2498 5.56218L9.18217 5.26987C8.76012 5.22965 8.39331 4.96315 8.22464 4.57418Z' fill='%23B3B7BC'/%3E%3C/svg%3E");
}

.product-reviews {
    justify-content: space-between;
}

.reviews-info__title {
    margin-bottom: 20px;
    font-weight: 900;
    font-size: 32px;
    line-height: 130%;
    color: #1E212C;
}

.reviews-info .rating {
    margin-bottom: 20px;
}

.reviews-info__caption {
    display: block;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #787A80;
}

.reviews-bar__list {
    display: flex;
    flex-direction: column;
    padding: 0;
}

.reviews-bar__item {
    display: flex;
    align-items: center;
}

.reviews-bar__item:not(:last-child) {
    margin-bottom: 8px;
}

.product-reviews .progress-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: inherit;
}

.progress-bar__star {
    margin-right: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #787A80;
}

.progress-bar__outter-line {
    overflow: hidden;
    position: relative;
    width: 356px;
    height: 4px;
    border-radius: 40px;
    background-color: #E5E8ED;
}

.progress-bar__inner-line {
    position: absolute;
    height: 4px;
    border-radius: 40px;
    left: 0;
}

.progress-bar__inner-line--excellent {
    background-color: #03CEA4;
}

.progress-bar__inner-line--good {
    background-color: #1FDF58;
}

.progress-bar__inner-line--normal {
    background-color: #F2EA2B;
}

.progress-bar__inner-line--not-bad {
    background-color: #F89828;
}

.progress-bar__inner-line--bad {
    background-color: #FF4242;
}

.progress-bar__quantity {
    margin-left: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #787A80;
}

.product-reviews .star_rating {
    color: #ECB800;
}

.moduleAccordion .accordion-button span {
    font-weight: 700;
}

.moduleAccordion .accordion-button span p {
    font-weight: 300;
}

.reviewViewRow {
    display: flex;
    width: 100%;
    gap: 20px;
    border-bottom: 1px #e5e5e5 solid;
    margin-bottom: 10px;
    padding-bottom: 10px;

}

.reviewViewRow:last-child {
    border: 0;
}

.reviewViewImg {
    width: 80px;
    min-width: 80px;
    height: 80px;
    display: inline-block;
    background-color: #f3f3f3;
    border-radius: 50%;
}

.reviewViewCnt {
    width: 100%;
    display: inline-block;
}

.reviewViewCnt span {
    width: 100%;
    display: inline-block;
    font-size: 12px;
    color: #666;
}

.reviewViewCnt p {
    margin-bottom: 0;
}

.inner_banner_section2 {
    width: 100%;
    display: inline-block;
    /* background-color: var(--secondary); */
    background-color: var(--primary-600);
    padding: 30px 0;
}

.inner_banner_section2 h2 {
    color: var(--darkClr);
}

.cartContantArea {
    width: 100%;
    display: inline-block;
    padding: 40px 0;
}

.cart_top_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.clearCartBtn {
    border: 1px #e5e5e5 solid;
    border-radius: 40px;
    padding: 3px 20px;
    color: #666;
}

.cart_cnt {
    width: 100%;
    display: inline-block;
    margin-top: 20px;
}

.cart_cnt_row {
    width: 100%;
    display: flex;
    box-shadow: 0px 2px 20px #f3f3f3;
    padding: 10px 15px;
    gap: 10px;
    align-items: center;
    margin-bottom: 20px;
}

.cart_cnt_row_img {
    width: 100px;
    height: 80px;
}

.cart_cnt_row_img img {
    width: 100%;
}

.cart_cnt_row_cnt {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cart_cnt_row_cnt_left {
    line-height: 17px;
}

.cart_cnt_row_cnt_left h4 {
    margin: 0;
}

.cart_cnt_row_cnt_left span {
    width: 100%;
    display: inline-block;
    font-size: 12px;
    color: #666;
}

.cart_cnt_row_cnt_right {
    display: flex;
    gap: 10px;
    align-items: center;
}

.cart_cnt_row_cnt_right span strong {
    width: 100%;
    display: inline-block;
}

.cartSingleDelete {
    width: 30px;
    height: 30px;
    border-radius: 50px;
    box-shadow: 0px 0px 10px #e5e5e5;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666;
    font-size: 15px;
}

.cart_right_checkout {
    width: 100%;
    height: auto;
    padding: 15px;
    height: 100%;
    box-shadow: 0px 2px 20px #f3f3f3;
    margin-bottom: 30px;
    padding-bottom: 80px;
    position: relative;
}

.cart_right_checkout_dtl {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 90%;
    justify-content: space-between;
}

.cart_right_checkout_dtl_cnt {
    width: 100%;
    height: auto;
    display: inline-block;
}

.cart_right_checkout_dtl_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    border-bottom: 1px #e5e5e5 dashed;
    margin-bottom: 0;
    padding: 5px 0;
}

.cart_right_checkout_dtl_row:last-child {
    border: 0;
}

.checkout_payble {
    font-size: 20px;
    color: #000;
    font-weight: 500;
}

.checkoutBtnCart {
    width: 100%;
    height: auto;
    display: inline-block;
    padding: 14px 0;
    background-color: var(--primary);
    color: #fff;
    text-align: center;
    position: absolute;
    left: 0;
    bottom: 0;
    transition: 0.2s ease-in-out;
}

.checkoutBtnCart:hover {
    background-color: var(--darkClr);
}

.login_section {
    width: 100%;
    height: auto;
    min-height: 70vh;
    display: inline-block;
    padding: 50px 0;
}

.login_box {
    width: 100%;
    height: auto;
    height: 100%;
    padding: 10px;
    background-color: #fff;
    box-shadow: 0px 2px 20px #f3f3f3;
    display: flex;
    justify-content: space-between;
}

.login_box_left_sc {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15px;
}

.login_box_left_sc h3 {
    width: 100%;
    display: inline-block;
    text-align: center;
    margin-bottom: 30px;
}

.login_box_left_sc h3 span {
    width: 100%;
    display: inline-block;
    text-align: center;
    font-size: 14px;
    color: #666;
}

.login_box_left_sc form {
    width: 100%;
}

.login_box_left_forgot_txt {
    width: 100%;
    display: inline-block;
    text-align: right;
    font-size: 14px;
    color: #666;
    margin: 15px 0;
}

.login_box_left_sc .btn-primary {
    width: 100%;
    display: inline-block;
}

.login_with_sec {
    width: 100%;
    display: inline-block;
}

.login_with_head {
    width: 100%;
    display: inline-block;
    border-top: 1px dashed #ccc;
    margin-top: 30px;
    position: relative;
}

.login_with_head span {
    font-size: 14px;
    color: #000;
    position: absolute;
    background-color: #fff;
    left: 0;
    right: 0;
    margin: auto;
    width: 110px;
    top: -13px;
    padding: 0 10px;
    text-align: center;
}

.login_with_sec_ico_row {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    gap: 10px;
}

.login_with_sec_ico {
    width: 40px;
    height: 40px;
    background-color: var(--primary);
    text-align: center;
    color: #fff;
    border: solid 3px #fff;
    line-height: 30px;
    border-radius: 50%;
    box-shadow: 0px 2px 20px #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s ease;
}

.login_with_sec_ico:hover {
    background-color: var(--darkClr);
}

.login_box_right_sec {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;
    background: rgb(2, 42, 90);
    background: linear-gradient(0deg, var(--darkClr) 0%, var(--primary) 93%);
    border-radius: 30px;
    padding: 40px 0;
}

.userNameTop {
    font-size: 14px;
    white-space: nowrap;
    display: inline-block;
    color: #666;
    margin-left: 5px;
}

.my_account_section {
    width: 100%;
    display: inline-block;
}

.my_account_top_section {
    width: 100%;
    display: inline-block;
    background-color: #fff;
    padding: 40px;
    margin-top: -30px;
    box-shadow: 0px 7px 30px #e5e5e5;
    position: relative;
}

.my_account_top_section::before {
    width: 1px;
    height: 100%;
    background-color: #e5e5e5;
    position: absolute;
    left: -50px;
    right: 0;
    top: 0;
    margin: auto;
    content: '';
}

.profile_section {
    width: 100%;
    display: inline-block;
}

.profile_top_dtl {
    width: 100%;
    display: flex;
    gap: 10px;
}

.profile_image {
    width: 80px;
    min-width: 80px;
    height: 80px;
    background-color: #f3f3f3;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    cursor: pointer;
}

.profile_image label {
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.profile_image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.delete-profile-pic-btn {
    position: absolute;
    top: 38px;
    left: 31px;
    /* background: rgb(69, 168, 217); */
    background-color: #e77324;
    border: none;
    color: #f01a1a;
    font-size: 12px;
    padding: 4px 8px 5px;
    cursor: pointer;
    z-index: 1;
    border-radius: 50%;
}

.delete-profile-pic-btn:hover {
    color: #cc0000;
}

.profile_usr_dtl_cnt {
    width: 100%;
    display: inline-block;
}

.profile_usr_dtl_cnt h3 {
    margin: 0;
}

.profile_usr_dtl_cnt p {
    font-size: 12px;
    margin: 0;
}



.my_account_banner_cnt {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.my_account_banner_cnt a {
    color: #666;
    /* color: #959191; */

}

.my_account_banner_cnt a.active {
    color: var(--primary);
}

.my_account_banner_cnt span {
    display: flex;
    gap: 15px;
}

/* .profile_image {
    width: 80px;
    min-width: 80px;
    height: 80px;
    background-color: #f3f3f3;
    border-radius: 50%;
    overflow: hidden;
}

.profile_image img {
    width: 100%;
    height: 100%;
} */



.profile_btn_row {
    width: 100%;
    padding: 10px 0;
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.profile_btn_row .btn {
    font-size: 13px;
}

.profile_btn_row1 {
    width: 100%;
    padding: 10px 0;
    display: inline-block;
    border-top: 1px #e5e5e5 solid;
    gap: 10px;
    margin-top: 10px;
}

.profile_btn_row1 p {
    width: 100%;
    display: inline-block;
}

.profile_learinin_report {
    width: 100%;
    display: inline-block;
}

.profile_learinin_report p {
    margin: 0;
}

.last_next_course_sec {
    width: 100%;
    display: inline-block;
    box-shadow: 0px 7px 30px #e5e5e5;
    padding: 20px;
    background-color: #fff;
}

.last_next_course_box {
    width: 100%;
    display: flex;
    gap: 10px;
}

.last_next_course_box_cnt {
    width: 100%;
    display: inline-block;
}

.last_next_course_box_img {
    width: 150px;
    overflow: hidden;
    position: relative;
}

.last_next_course_box_img img {
    width: 100%;
}

.last_next_course_box_cnt p {
    margin: 0;
}

.play_ico {
    width: 40px;
    height: 40px;
    background-color: #fff;
    padding: 10px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;
}

.edit_profile_view_sec {
    width: 100%;
    display: inline-block;
    box-shadow: 0px 7px 30px #e5e5e5;
    padding: 15px;
}

.tabs-left {
    border-bottom: none;
    border-right: 1px solid #ddd;
}

.tabs-left>li {
    float: none;
    margin: 0px;

}

.tabs-left>li.active>a,
.tabs-left>li.active>a:hover,
.tabs-left>li.active>a:focus {
    border-bottom-color: #ddd;
    border-right-color: transparent;
    background: #f90;
    border: none;
    border-radius: 0px;
    margin: 0px;
}

.nav-tabs>li>a:hover {
    /* margin-right: 2px; */
    line-height: 1.42857143;
    border: 1px solid transparent;
    /* border-radius: 4px 4px 0 0; */
}

.tabs-left>li.active>a::after {
    content: "";
    position: absolute;
    top: 10px;
    right: -10px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;

    border-left: 10px solid #f90;
    display: block;
    width: 0;
}

.edit_profile_view_sec .nav-tabs {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: start;
    align-items: start;
    border: 0;
}

.edit_profile_view_sec .nav-tabs .nav-link {
    width: 100%;
    text-align: left;
    border: 0;
    color: #666;
}

.edit_profile_view_sec .nav-tabs .nav-link.active {
    color: var(--primary);
}

.purchased_qst_sec {
    width: 100%;
    display: inline-block;
    margin-bottom: 20px;
    box-shadow: 0px 7px 30px #e5e5e5;
    margin-top: 10px;
    padding: 15px;
}

.purchased_qst_sec p {
    margin: 0;
}

.purchased_qst_sec .progress {
    margin: 0;
}

.purchased_qst_sec .progress {
    height: 5px;
}

.purchased_qst_sec .table td {
    font-size: 14px;
    color: #666;
    padding: 13px 5px;
}

.mylearning_contant_section {
    width: 100%;
    display: inline-block;
    padding: 20px;
    padding-top: 40px;
    margin-top: -15px;
}

.mylearning_contant_section_bx {
    width: 100%;
    display: inline-block;
    background-color: #fff;
    box-shadow: 0px 7px 30px #e5e5e5;
    padding: 10px;
}

.mylearning_video_box {
    width: 100%;
    display: inline-block;
    background-color: #fff;
    border: solid 1px #e5e5e5;
    padding: 5px;
}

/* .moduleListACCRD ul{padding: 0;} */

.moduleListACCRD li {
    margin-bottom: 5px;
}

.moduleListACCRD a {
    display: flex;
    justify-content: space-between;
    color: #666;
    font-size: 13px;
}

.moduleListACCRD .accordion-button:not(.collapsed) {
    background-color: #fff;
}

.moduleListACCRD p {
    margin: 0;
}

.video_tittle_box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
}

.course_status_box {
    width: 100%;
    display: inline-block;
    background-color: #fff;
    box-shadow: 0px 2px 30px #e5e5e5;
    padding: 10px;
}

.course_status_box_cnt .chart-wrapper {
    max-height: max-content;
    position: relative;
    /* margin-top: 50px; */
    width: 80px;
}

.course_status_box_cnt .chart-wrapper #myChart {
    position: relative;
    z-index: 1;
}

.course_status_box_cnt .chart-wrapper #chartjs-tooltip {
    left: 0;
    top: 0;
    font-family: Arial, sans-serif;
    font-style: normal;
    right: 0;
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 0;
    height: 100%;
    padding: 0;
    opacity: 1 !important;
    align-items: center;
    color: gray;
    font-size: 40px !important;
    font-weight: 800 !important;
}

.course_status_box_cnt {
    display: flex;
    gap: 10px;
    align-items: center;
}

.course_status_box_cnt p {
    margin: 0;
}

.social_sec {
    display: flex;
    gap: 10px;
    align-items: center;
    width: 100%;
    border-top: 1px #e5e5e5 solid;
    padding-top: 10px;
}

.social_sec__ico {
    width: 30px;
    height: 30px;
    background-color: var(--primary);
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 18px;
}

.badges-container {
    text-align: center;
}


.continue-shopping {
    border-radius: 8px;
    font-size: 13px;
    padding: 5px 10px 6px;
    background: #32b1d9;
    color: white;
    border: none;
    margin-top: 2px;
    font-weight: bold;

}

.continue-shopping:hover {
    background-color: var(--darkClr);
}

@media (max-width:1400px) {
    .main_header .search-header {
        width: 20%;
    }

    .main_header .col-md-3 {
        gap: 10px !important;
    }

    .main_header .nav-link {
        font-size: 14px !important;
    }

    .main_header .btn-primary {
        font-size: 14px !important;
    }
}

@media (max-width:1200px) {
    .main_header .col-md-3 {
        width: 33.33333333%;
    }
}

@media (max-width:991px) {
    .main_header .search-header {
        width: 100%;
    }

    .main_header .col-md-3 {
        width: 100%;
        justify-content: center !important;
    }
}

@media (max-width:768px) {
    .banner_section {
        height: auto;
        padding: 80px 0;
        background-position: left;
    }

    .banner-image {
        display: none;
    }

    .category_selection_home {
        flex-wrap: wrap;
        justify-content: start;
        margin-top: 20px;
    }

    .aboutUs_section .viewAllBtn {
        margin-bottom: 20px;
    }

    .main_header.fixed .search-header {
        display: none;
    }

    .login_box {
        flex-direction: column;
    }

    .login_box_left_sc {
        width: 100%;
    }

    .login_box_right_sec {
        width: 100%;
    }

    .profile_btn_row1 {
        margin-bottom: 30px;
    }

    .last_next_course_box {
        margin-bottom: 20px;
    }

    .my_account_top_section::before {
        display: none;
    }
}

@media (max-width:500px) {
    .cart_cnt_row_cnt {
        flex-direction: column;
    }

    .cart_cnt_row_cnt {
        align-items: start;
        row-gap: 15px;
    }
}

/*chat*/
.chat-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.chat-wrapper .chat-sidebar {
    width: 100%;
}

@media (min-width: 768px) {
    .chat-wrapper .chat-sidebar {
        width: 300px;
    }
}

@media (min-width: 1200px) {
    .chat-wrapper .chat-sidebar {
        width: 390px;
    }
}

.chat-wrapper .chat-sidebar.profile-setting {
    padding: 1.5rem;
}

.chat-wrapper .chat-main {
    width: 100%;
    margin-top: 1.875rem;
}

@media (min-width: 768px) {
    .chat-wrapper .chat-main {
        width: calc(100% - 324px);
        margin-top: 0;
    }
}

@media (min-width: 1200px) {
    .chat-wrapper .chat-main {
        width: calc(100% - 414px);
    }
}

.chat-main-profile {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-top: 1.875rem;
    gap: 0.9375rem;
}

.chat-main-profile .img {
    position: relative;
    width: 80px;
    height: 80px;
}

.chat-main-profile .img::after {
    position: absolute;
    content: "";
    bottom: 4px;
    inset-inline-end: 9px;
    width: 12px;
    height: 12px;
    background-color: var(--success-main);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.chat-main-profile .img img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    -o-object-fit: cover;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.chat-search {
    padding: 0 1.5rem;
    border-top: 1px solid var(--neutral-200);
    border-bottom: 1px solid var(--neutral-200);
    display: flex;
    align-items: center;
}

.chat-search .icon {
    font-size: 1.375rem;
    line-height: 1;
    padding-top: 5px;
}

.chat-search input {
    flex-grow: 1;
    border: none;
    height: 48px;
    background-color: transparent;
    font-size: 1.125rem;
    padding-inline-start: 0.625rem;
}

.chat-all-list {
    max-height: 534px;
    overflow-y: auto;
}

@media (min-width: 1200px) {
    .chat-all-list {
        max-height: 400px;
    }
}

@media (min-width: 1400px) {
    .chat-all-list {
        max-height: 534px;
    }
}

.chat-all-list::-webkit-scrollbar {
    width: 10px;
    background-color: var(--white);
}

.chat-all-list::-webkit-scrollbar-thumb {
    background-color: var(--bg-color);
    border: 3px solid var(--white);
}

.chat-sidebar-single {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0.6875rem 1.5rem;
    cursor: pointer;
}

.chat-sidebar-single.top-profile {
    padding: 1.5rem 1.5rem 1rem 1.5rem;
}

.chat-sidebar-single.active .img::after {
    background-color: var(--success-main);
}

.chat-sidebar-single .img {
    position: relative;
}

.chat-sidebar-single .img::after {
    position: absolute;
    content: "";
    bottom: 1px;
    right: 2px;
    width: 8px;
    height: 8px;
    background-color: var(--neutral-400);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.chat-sidebar-single .img img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    -o-object-fit: cover;
}

.chat-sidebar-single .info {
    flex-grow: 1;
    padding: 0 0.75rem;
}

.chat-sidebar-single .action {
    margin-inline-start: auto;
}

.chat-main {
    display: flex;
    flex-flow: column;
}

.chat-main .chat-sidebar-single {
    padding: 1.25rem 1.5rem;
    border-bottom: 1px solid var(--input-form-light);
}

.chat-main .chat-empty {
    flex-grow: 1;
    padding: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.chat-message-list {
    flex-grow: 1;
    min-height: 350px;
    max-height: 511px;
    padding: 1.5rem;
    display: flex;
    flex-flow: column;
    overflow-y: auto;
}

@media (min-width: 1200px) {
    .chat-message-list {
        max-height: 375px;
    }
}

@media (min-width: 1400px) {
    .chat-message-list {
        max-height: 511px;
    }
}

.chat-message-list::-webkit-scrollbar {
    width: 10px;
    background-color: var(--white);
}

.chat-message-list::-webkit-scrollbar-thumb {
    background-color: var(--bg-color);
    border: 3px solid var(--white);
}

.chat-single-message+.chat-single-message {
    margin-top: 2rem;
}

.chat-single-message {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
}

@media (min-width: 576px) {
    .chat-single-message {
        width: 90%;
    }
}

@media (min-width: 992px) {
    .chat-single-message {
        width: 60%;
    }
}

.chat-single-message.left .chat-message-content {
    background-color: var(--input-bg);
    border-radius: 16px 16px 16px 0;
    -webkit-border-radius: 16px 16px 16px 0;
    -moz-border-radius: 16px 16px 16px 0;
    -ms-border-radius: 16px 16px 16px 0;
    -o-border-radius: 16px 16px 16px 0;
}

.chat-single-message.left .chat-message-content p {
    color: var(--text-primary-light);
}

.chat-single-message.left .chat-message-content p.chat-time {
    color: var(--text-secondary-light);
    text-align: end;
}

.chat-single-message.right {
    margin-inline-start: auto;
}

.chat-single-message.right .chat-message-content {
    width: 100%;
    background-color: var(--brand);
    border-radius: 16px 16px 0 16px;
    -webkit-border-radius: 16px 16px 0 16px;
    -moz-border-radius: 16px 16px 0 16px;
    -ms-border-radius: 16px 16px 0 16px;
    -o-border-radius: 16px 16px 0 16px;
}

.chat-single-message.right .chat-message-content p {
    color: #fff;
}

.chat-single-message.right .chat-message-content p.chat-time {
    color: #fff;
}

.chat-single-message .chat-message-content {
    width: calc(100% - 56px);
    padding: 1.25rem;
}

.chat-single-message .chat-message-content .chat-time {
    font-size: 0.75rem;
}

.chat-message-box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.9375rem;
    padding: 1rem 1.5rem;
    border-top: 1px solid var(--neutral-300);
}

.chat-message-box input {
    flex-grow: 1;
}

.chat-message-box-action {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1.25rem;
}

/*----------------------payment-gateways----------------*/
.payment-gateways {
    grid-template-columns: 1fr;
}

.payment-gateway-centerBox {
    width: 100%;
    max-width: 1250px;
}

.payment-gateways-Box {
    width: 100%;
    height: auto;
    border-radius: 7px;
    background: #fff;
    box-shadow: -20px 7px 34px 0px rgba(0, 0, 0, 0.1);
    padding: 20px;
    float: left;
    min-height: 400px;
}

.payment_tab_reg {
    width: 100%;
    height: 100%;
    float: left;
    border: 0;
    /* background: #eafaff; */
    background: #e7732445;
    padding: 10px;
}

.payment_tab_reg .nav-link {
    width: 100%;
    height: auto;
    display: inline-block;
    text-align: left;
    border: 0;
    border-radius: 0;
    margin-bottom: 15px;
    color: #242424;
    position: relative;
    z-index: 1;
}

.payment_tab_reg .nav-link.active {
    background-color: transparent;
    color: #fff;
}

.payment_tab_reg .nav-link.active::after {
    background-color: var(--primary);
    color: #fff;
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    z-index: -1;
    top: 0;
    border-radius: 10px 0 0 10px;
    left: 8px;
}

.payment__tab_cnt {
    min-height: 265px;
    height: 100%;
    border-left: 0 !important;
}

.renew_btn {
    width: auto;
    max-width: 250px;
    padding: 10px 40px;
}

.BackBtn {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
    gap: 12px;
}

.plan-choose {
    width: 55%;
    /* background: #0f7a9b; */
    background: #e77628;
    border-radius: 5px;
    border: none;
    color: white;
    font-size: 18px;
    padding: 10px;
    font-weight: bold;
    transition: 0.2s ease-in-out;
}

.plan-choose-back {
    width: 55%;
    background: #333636;
    border-radius: 5px;
    border: none;
    color: white;
    font-size: 18px;
    padding: 10px;
    font-weight: bold;
    transition: 0.2s ease-in-out;
}

.stripe-payment-btn {
    margin-top: 1em;
    padding: 9px 9px 11px;
    border: medium;
    background: rgb(51, 54, 54);
    color: white;
    border-radius: 2em;
    width: 100%;
}

.question-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.question-container h6 {
    margin: 0;
}

.reply-btn {
    text-align: center;
    /* No float needed with flexbox */
}

.reply-section {
    height: auto;
    min-height: 50px;
    background: #f3f3f3;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-top-left-radius: 10px;
    border: 2px solid transparent;
    transition: border-color 0.3s ease;
    border-bottom: 0;
}

.reply-section.active {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.answersList-container {
    display: flex;
    justify-content: space-between;
    align-items: center;


}

.comment-send-btn {
    float: right;
    border: none;
    padding: 6px 13px 5px;
    background: #2e89bf;
    color: #fffefb;
    border-radius: 6px;
    transition: transform 0.2s ease, background-color 0.2s ease;
}

.comment-send-btn:active {
    transform: scale(0.95);
}

.commentImg {
    width: 80px;
    min-width: 80px;
    height: 80px;
    display: inline-block;
    background-color: #f3f3f3;
    border-radius: 50%;
    overflow: hidden;
}

.commentImg img {
    width: 100%;
    /* Makes the image cover the entire container */
    height: 100%;
    /* Ensures the image height matches the container */
    object-fit: cover;
    /* Keeps the image aspect ratio while covering the area */
    border-radius: 50%;
    /* Ensures the image is circular as well */
}

.navigation_buttons {
    display: flex;
    gap: 10px;
}

.video-prev-btn {
    background-color: black;
    border: none;
    color: white;
}

.video-prev-btn:hover {
    background-color: rgb(163, 160, 160);
    color: #000;
}

.task_title_active {
    text-decoration: underline;
    color: rgb(59, 186, 224);
}

.task_title:hover {
    text-decoration: underline;
    color: rgb(59, 186, 224);
}

.kyc-btn {
    background: #040136 !important;
    color: white !important;
}

.kyc-btn:hover {
    background: #040136;
    color: white;
}

.kyc-table {
    width: 100%;

}

.kyc-image-container {
    border: 3px solid #fff;
    border-radius: 2px;
    cursor: pointer;
    height: 40px;
    overflow: hidden;
    position: relative;
    width: 40px;
    display: inline-block;
}

.kyc-table-head {
    background-color: #bbe9fb96;
}

.kyc-table-head th {
    padding: 5px;
}

.kyc-dlt {
    cursor: pointer;
}

.kyc-dlt:hover {
    color: #c7465c;
}

.noti-container {
    position: relative;
}

.notification-count {
    position: absolute;
    top: -9px;
    left: -11px;
    font-size: small;
    /* background: #5cc2e1; */
    background: #e77324;
    color: white;
    border-radius: 50%;
    border: none;
    padding: 0px 5px;
}

.cart-count {
    position: absolute;
    top: -8px;
    right: 16px;
    font-size: small;
    background: #e77324;
    color: white;
    border-radius: 50%;
    border: none;
    padding: 0px 5px;
}

.noti-view-all {
    text-align: center;
    background-color: #2233312e;
    cursor: pointer;
    font-size: 14px;
}

.notification-items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;
}

.noti-counter {
    background: #f2a009;
    padding: 3px 12px;
    border-radius: 50%;
    color: white;
    font-size: 15px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.noti-content {
    flex: 1;
}

.noti-heading {
    font-weight: bolder;
    font-size: 15px;
    text-transform: capitalize;
    margin-bottom: 0;
    /* Removed gap between type and message */
}

.noti-quote {
    margin: 0;
    padding-left: 10px;
    /* font-style: italic; */
    color: #555;
}

blockquote cite {
    display: block;
    text-align: left;
    font-size: 14px;
    color: #888;
    margin-top: 5px;
}

.noti-date {
    font-size: 14px;
    color: #888;
    flex-shrink: 0;
    text-align: right;
    /* align-self: flex-start; */
}

@media (max-width: 768px) {
    .notification-items {
        flex-direction: column;
        gap: 5px;
    }

    .noti-date {
        text-align: left;
        margin-top: 5px;
    }
}

.mark-all-read-noti {
    text-align: end;
    font-size: small;
    cursor: pointer;
}

.mark-all-read-noti:hover {
    color: rgb(8, 101, 163);
}

.noti-view-all:hover {
    background-color: #040136;
    color: white;
}





.backTphomebtn {
    border: none;
    margin-top: 1em;
    background: #1d5be8;
    color: white;
    padding: 7px 14px 10px;
    border-radius: 2em;
    transition: all 0.3s ease;
}

.backTphomebtn:hover {
    background-color: #497bec;
}

.backTphomebtn:active {
    transform: scale(0.95);
    background-color: #497bec;
}

.my_purchase_invoice_section {
    width: 100%;
    display: inline-block;
    background-color: #fff;
    padding: 40px;
    box-shadow: 0px 7px 30px #e5e5e5;
    margin: 2em;
}

.invoice-right-side-text {
    text-align: end;
}

.customer-details p {
    margin-bottom: 0 !important;

}

.invoice-details {
    text-align: end;
}

@media print {
    .container {
        padding: 0 !important;
        margin: 0 !important;
        width: 100% !important;
    }

    .my_purchase_invoice_section {
        margin: 0 !important;
        width: 100% !important;
        box-shadow: none !important;
    }

    .my_account_section {
        width: 100%;
    }

    /* Hide any elements you don't want to print */
    .no-print_icon {
        display: none !important;
    }

    /* Ensure the table and content stretch across the full width */
    .table {
        width: 100% !important;
    }

    /* Remove any potential paddings from the table */
    .table-style {
        padding: 0 !important;
    }
}

@media print and (orientation: landscape) {
    /* Additional styles for landscape */
    .my_purchase_invoice_section {
        width: 100% !important;
    }
}

@media print and (orientation: portrait) {
    /* Additional styles for portrait */
    .my_purchase_invoice_section {
        width: 100% !important;
    }
    .invoice-details {
        text-align: start !important;
    }
}


.pagination {
    text-align: center;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    /* Allows wrapping of elements if they overflow */
}

.pages {
    display: flex;
    align-items: center;
    justify-content: center;
    /* Center the page numbers */
    flex-wrap: wrap;
    /* Allows wrapping of the page numbers on smaller screens */
    gap: 10px;
    /* Space between page numbers */
}

.page-number {
    cursor: pointer;
    background-color: white;
    color: #999;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .4s ease;
    font-size: 0.9em;
}

.page-active {
    font-size: 1.3em;
    height: 40px;
    width: 40px;
    /* background-color: #0057b3; */
    background-color: #e77324;
    color: white;
}

.arrow {
    width: auto;
    padding: 8px 16px;
    background-color: #ffffff00;
    /* color: #0057b3; */
    color: #e77324;
    border: none;
    cursor: pointer;
    margin: 0 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.arrow:hover {
    /* color: #0056b3; */
    color: #e77324;
}

.arrow:disabled {
    background-color: #ffffff00;
    color: #ccc;
    cursor: not-allowed;
}

#prevPage {
    margin-right: 20px;
}

#nextPage {
    margin-left: 20px;
}

.arrow {
    font-size: 1.2em;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .pagination {
        flex-direction: column;
        /* Stack pagination elements vertically on small screens */
        gap: 10px;
        /* Add space between the elements */
    }

    .arrow {
        width: 100%;
        /* Make the arrows full-width on small screens */
        justify-content: center;
    }

    .pages {
        width: auto;
        justify-content: center;
    }
}

@media (max-width: 480px) {
    .page-number {
        height: 25px;
        width: 25px;
        font-size: 0.8em;
    }

    .page-active {
        height: 35px;
        width: 35px;
        font-size: 1.2em;
    }

    .arrow {
        font-size: 1em;
        padding: 6px 12px;
    }
}

.ellipsis {
    padding: 0 8px;
    color: #999;
    font-size: 1em;
}
.purchase-course-container{
    max-height: 325px;
    overflow-y: auto;
}
.skeleton-circles-container {
    display: flex;
    gap: 10px;
}

.skeleton-item {
    display: inline-block;
}
